<template>
  <v-container fluid grid-list-lg style="overflow: scroll">



    <v-card class="rounded-xl" outlined>
      <v-card-title primary-title>
        <v-layout row wrap align-center class="mb-3">
          <v-flex shrink>
            <v-btn icon outlined @click.stop.prevent="$router.go(-1)">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-flex>
          <v-flex shrink class="font-weight-bold black--text">
            Wallet
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex shrink class="text-right">
            <SearchBar v-model="search" />
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-card flat class="cardTitle" :color="$style.card.background">
          <v-card-text>
            <v-layout row wrap>
              <v-flex shrink>
                <DateTimePicker :range="true" v-model="dateTime" :initStartDate="getStart" :initEndDate="getEnd"
                  @submit="getList()" :defaultRange="defaultRange" />
              </v-flex>
              <!-- <v-flex class="ml-2" shrink> -->
              <!-- <v-select
                hide-details
                item-text="name"
                :items="items"
                return-object
                v-model="selected"
                solo
              ></v-select> -->
              <!-- </v-flex> -->
              <v-spacer></v-spacer>
              <v-flex shrink v-if="
  !$vuetify.breakpoint.xsOnly
">
                <DateSelectorView :dateTime="dateTime" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <v-data-table class="table-header" hide-default-footer :headers="headers" :items-per-page="serials.length"
          :items="serials" :loading="loading">
          <template v-slot:item="{ item }">
            <!-- :class="{ credit: item.type === 'CREDIT' }" -->
            <tr class="pointer" :class="{ credit: item.type === 'CREDIT', debit: item.type === 'DEBIT' }"
              @click="viewInvoice(item)">
              <td class="text-left small-td">
                {{ item.createdAt | moment('MM/DD/YYYY LT') }}
              </td>
              <td class="text-left small-td">
                {{ item.type }}
              </td>
              <td class="text-left small-td">
                <div v-if="item.metadata && item.metadata.phoneNumber">
                  Phone Number: {{ item.metadata.phoneNumber }}</div>
                <div v-if="item.metadata && item.metadata.amount && item.type === 'DEBIT'">Amount: {{
    item.metadata.amount | currency
}}</div>
                <div v-if="item.metadata && item.metadata.id">Add by card ending with: {{
    item.metadata.payment_method_details.card.last4
}}</div>
                <!-- {{ item.metadata }} -->
              </td>
              <td class="text-right small-td">
                {{ item.amount | currency }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>


    </v-card>
  </v-container>
</template>
<script>
import historyService from '@/modules/History/service.js';
import DateTimePicker from '@/components/DateTimePicker';
import DateSelectorView from '@/components/DateSelectorView.vue';

export default {
  name: 'sim-history',
  props: ['hideHeader', 'hideCreate', 'ProductId', 'defaultRange'],
  data() {
    return {
      activationDialog: false,
      selectedSerial: null,
      edit: false,
      status: 0,
      contact: JSON.parse(localStorage.getItem(
        `${process.env.VUE_APP_STORAGE_PREFIX}contact`
      )),
      headers: [
        {
          text: 'Date',
          align: 'left',
          value: 'createdAt',
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type',
        },
        {
          text: 'Detail',
          align: 'left',
        },
        {
          text: 'Amount',
          align: 'right',
          value: 'serialNumber',
        },
      ],
      start: this.$moment().startOf('day'),
      end: this.$moment().endOf('day'),
      dateTime: {
        start: this.$moment().startOf('day').toISOString(),
        end: this.$moment().endOf('day').toISOString(),
      },
      orderBy: ['createdAt', 'true'],
      search: null,
      serials: [],
      count: 0,
      loading: false,
    };
  },
  components: {
    DateTimePicker,
    DateSelectorView
  },
  computed: {
    getStart() {
      if (this.defaultRange) return null
      return this.start
    },
    getEnd() {
      if (this.defaultRange) return null
      return this.end
    },
  },
  created() {
    // this.contact = JSON.parse(localStorage.getItem(
    //   `${process.env.VUE_APP_STORAGE_PREFIX}contact`
    // ))
    // this.AssignedContactId = contact.id
    this.getList();

  },
  watch: {
    search() {
      if (this.search.length > 3 || this.search.length === 0) {
        this.getList();
      }
    },
  },
  methods: {
    viewInvoice(item) {
      console.log('item', item)
      // const hashId = hashids.encode(invoice.id);
      // const routeData = this.$router.resolve({
      //   path: item.metadata.receipt_url,
      // });
      if (item.metadata && item.metadata.receipt_url) {
        window.open(item.metadata.receipt_url, "newwindow", "width=850, height=1100");
      }
    },
    getList() {
      this.loading = true;
      console.log('this.search is :', this.search)
      let start = this.dateTime.start;
      let end = this.dateTime.end;
      return historyService
        .getWallet({
          start,
          end,
          limit: 500,
          search: this.search,
          orderBy: this.orderBy,
          ContactId: this.contact.id,
          cellsimPortalHistory: true
        })
        .then(response => {
          console.log('this.search is :', response)
          this.serials = response.data;
          this.count = response.count;
          this.loading = false;
          return response;
        });
    },
  },
};
</script>

<style scoped>
/* CSS */
.credit {
  color: green;
  font-weight: bold;
}

.debit {
  color: red;
  font-weight: bold;
}
</style>